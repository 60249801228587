import React from 'react';
import Layout from '../components/Layout';
import NotFound from '../components/NotFound';

const Index = () => (
  <Layout>
    <NotFound />
  </Layout>
);

export default Index;
